/**
 * @module ThankYou
 */
import React from 'react';
import {
  callSegmentPage,
  callSegmentTrack,
} from '@io/web-tools-io/dist/utils/helpers/analytics';
import { timestampToDate } from '@io/web-tools-io/dist/utils/helpers/date';
import { getQueryParams } from '@io/web-tools-io/dist/utils/helpers/queryParams';
import { EVENTS, PARAMETERS, SCREENS } from '../../../../helpers/constants';
import Button from '../../../../components/ButtonItem/ButtonItem';
import { STRINGS } from '../../helpers/strings';

/**
 * Represents the Thank You step for the form flow.
 *
 * @param {object} props - The component props object.
 * @param {Array} props.campuses - Array of campus data objects.
 * @param {number} props.stringStepIndex - The number that associates the step with strings.
 * @param {object} props.userInfo - The user info data object.
 *
 * @returns {React.ReactElement} The MinistryTeam component.
 */
const ThankYou = ({ campuses, stringStepIndex, userInfo }) => {
  const strings = {
    ...STRINGS,
    step: STRINGS.steps[stringStepIndex],
  };
  const [needsBgcForm, setNeedsBgcForm] = React.useState(false);

  /**
   * Single-run convenience effect to fire off Segment page view event and set
   * state for Background Check form needs.
   */
  React.useEffect(() => {
    if (campuses && userInfo) {
      const { FormName, NeedsForm, Url } = userInfo.formData;

      /**
       * Start an object of properties not necessarily present to avoid sending
       * any null values for the track event. Also gather date time stamp with
       * the event, with Data Team request of 6-digit millisecond value and UTC.
       */
      const eventTrackProperties = {};
      const getUtmSource = getQueryParams('utm_source');
      const getUtmTerm = getQueryParams('utm_term');
      const now = new Date();
      let dateTimeStamp = timestampToDate(now.getTime());
      dateTimeStamp = dateTimeStamp.replace('T', ' ').replace('Z', '');
      const submittedAt = dateTimeStamp.split('.');
      submittedAt[1] = `${submittedAt[1]}000`;

      /**
       * Only add the following if present as query params.
       */
      if (typeof getUtmSource === 'string') {
        eventTrackProperties.utm_source = getUtmSource;
      }
      if (typeof getUtmTerm === 'string') {
        eventTrackProperties.utm_term = getUtmTerm;
      }
      if (userInfo.person_alias_id) {
        eventTrackProperties.user_id = userInfo.person_alias_id;
      }

      setNeedsBgcForm(
        NeedsForm &&
          FormName?.toLowerCase() ===
            strings.step.formNames.backgroundCheck.toLowerCase() &&
          Url,
      );
      const campus = campuses.find((c) => {
        return c.Guid.toLowerCase() === userInfo.campus.toLowerCase();
      });
      const opportunity = campus?.AvailableOpportunities?.find((o) => {
        return o.Value.toLowerCase() === userInfo.opportunity.toLowerCase(); // Value is the opportunity GUID value.
      });
      callSegmentPage({
        category: null,
        name: SCREENS.multiStep.thankYou,
        properties: {},
      });
      callSegmentTrack({
        event: EVENTS.multiStep.signUpFinished,
        properties: {
          ...eventTrackProperties,
          campus: campus?.Name,
          email: userInfo?.email,
          first_name: userInfo?.first_name,
          form_name: PARAMETERS.multiStep.formName,
          last_name: userInfo?.last_name,
          logged_in: !!userInfo.person_alias_id,
          phone_number: userInfo?.phone,
          preferred_campus: null, // User preferred campus not presently available without specific call to API to get user-specific data (such as with Web Giving).
          referrer: document?.referrer || null,
          submitted_at: `${submittedAt.join('.')} UTC`,
          title: document?.title || '',
          type: opportunity?.Text,
          url: window?.location?.href,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campuses, userInfo]);

  /**
   * Lottie Animation Note:
   * The supplied Lottie animation was created a while back and is not actually
   * editable without our PDs doing some magic in Adobe After Effects and having
   * to jump through a bunch of hoops and spend a lot of time. This is an issue
   * because the animation has the circle/check mark small and centered in the
   * context of the larger animation boundary (half the size), so there is a lot
   * of blank space around the actual artwork. As such, the implementation here
   * adjusts the size as needed and uses negative 50% margins to re-center the
   * now-cropped animation in its parent container. Not ideal, but saves our
   * Design team a lot of work and time.
   */
  return (
    <section className="section thank-you full-screen container display-flex flex-column text-center justify-center">
      <div className="inner-section align-center justify-center display-flex flex-column">
        <div className="align-center confirmation-animation-wrapper">
          <lottie-player
            autoplay={true}
            background="transparent"
            speed="1"
            src={
              'https://lottie.host/8ebb27bd-34e0-4cd8-bd93-56a3c0c53d18/uWW9fwk7Os.json'
            }
            style={{
              height: 144,
              marginLeft: '-50%',
              marginTop: '-50%',
              width: 144,
            }}
          ></lottie-player>
        </div>
        <h1 className="text-title mb-normal">{strings.step.titles.main}</h1>
        <p className="text-paragraph_normal line-height-normal mb-normal">
          {strings.step.messages.main}
        </p>
        {/* If form submit response data has need for background check form, show necessary UI elements. */}
        {needsBgcForm ? (
          <>
            <hr className="separator-light mb-normal mt-normal" />
            <p className="text-paragraph_normal text-weight-bold line-height-normal mb-normal">
              {strings.step.messages.nextStepBGC}
            </p>
            <Button
              buttonSize="large"
              style="btn-primary"
              text={strings.step.labels.startBackgroundCheck}
              url={userInfo.formData?.Url}
            />
          </>
        ) : null}
      </div>
    </section>
  );
};

export default ThankYou;
