/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/**
 * @module Locations
 */
// eslint-disable-next-line no-unused-vars

import React from 'react';
import {
  callSegmentPage,
  callSegmentTrack,
} from '@io/web-tools-io/dist/utils/helpers/analytics';
import { getStateNameFromCode } from '@io/web-tools-io/dist/utils/helpers/locationsHelper';
import { getQueryParams } from '@io/web-tools-io/dist/utils/helpers/queryParams';
import { ACTIONS, EVENTS, SCREENS } from '../../../../helpers/constants';
import StepsIndicator from '../StepsIndicator/StepsIndicator';
import InputListCard from '../../components/InputListCard';
import MyLocationIcon from '../../components/MyLocationIcon';
import BackButton from '../../components/BackButton';
import StickyButton from '../../components/StickyButton';
import { STRINGS } from '../../helpers/strings';
import getGuidFromValue from '../../helpers/getGuidFromValue';
import './Location.scss';

/**
 * Represents the Locations selection step for the form flow.
 *
 * @param {object} props - The component props object.
 * @param {number} props.activeStep - The step number associated with the screen.
 * @param {Array} props.campuses - Array of campus data objects.
 * @param {Function} props.handleActiveStep - Handler function for setting active step.
 * @param {Function} props.handleUserInfo - Handler function for updating user info data object.
 * @param {number} props.stringStepIndex - The number that associates the step with strings.
 * @param {object} props.userInfo - The user info data object.
 * @param {boolean} props.userInUS - Boolean flag that indicates if the user is located in the United States.
 *
 * @returns {React.ReactElement} The Locations Screen component.
 */
const Location = ({
  activeStep,
  campuses,
  handleActiveStep,
  handleUserInfo,
  stringStepIndex,
  userInfo,
  userInUS,
}) => {
  const [defaultPreferredCampus, setDefaultPreferredCampus] =
    React.useState(null);
  const [defaultCampusHasChecked, setDefaultCampusHasChecked] =
    React.useState(false);
  const [campusInformation, setCampusInformation] = React.useState();
  const [globalCampus, setGlobalCampus] = React.useState({});
  const [restOfStates, setRestOfStates] = React.useState({});
  const [sortedStates, setSortedStates] = React.useState([]);
  const [formErrors, setFormErrors] = React.useState({});
  const strings = {
    ...STRINGS,
    step: STRINGS.steps[stringStepIndex],
  };

  /**
   * Convenience function to check and return validity of form step.
   *
   * @param {object} values - The object of value which determines if a option has been selected.
   *
   * @returns {boolean} Boolean flag denoting whether or not the step is valid.
   */
  const isValid = (values) => {
    const errors = {};
    if (!values.campus) {
      errors.campus = 'Please select an option below.';
    }
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  /**
   * Handler function for input change event.
   *
   * @param {Event} event - The change Event object.
   */
  const handleChange = (event) => {
    const value = event.currentTarget.getAttribute('data-value');
    // Explicitly target `campus` as attribute name rather than dynamic [name],
    // since there are now multiple radio button groups for default preferred
    // campus, global campus, and full campus list.
    handleUserInfo({ ...userInfo, campus: value });
  };

  /**
   * Handler function for back button click.
   *
   * @returns {Function} Handler function for active step setting.
   */
  const handleBack = () => {
    return handleActiveStep(activeStep - 2);
  };

  /**
   * Handler function for next button click.
   *
   * @param {Event} event - The Event object associated with the submit event.
   *
   * @returns {Function} Handler function for continuing on to the last step of the form.
   */
  const handleSubmit = (event) => {
    event.preventDefault();
    if (!isValid(userInfo)) {
      return null;
    }
    /* istanbul ignore next */
    callSegmentTrack({
      event: EVENTS.buttonAction,
      properties: {
        action: ACTIONS.clicked,
        component: 'Serving Form',
        component_url: null,
        label: event.currentTarget.textContent,
        logged_in: !!userInfo.person_alias_id,
        preferred_campus: null, // User preferred campus not presently available without specific call to API to get user-specific data (such as with Web Giving).
        referrer: document?.referrer || null,
        screen: SCREENS.multiStep.location,
        title: document?.title || '',
        url: window?.location?.href,
        user_id: userInfo.person_alias_id,
      },
    });
    return handleActiveStep(activeStep);
  };

  /**
   * Convenience effect to set campus information and sort states.
   * Note: Test ignore comments used since absence of data is severe edge case
   * if data coming back from API changes or isn't consistent. All campus data
   * is included in the test data, which is a subset of the real data coming
   * from the production version of the site.
   */
  React.useEffect(() => {
    if (campuses) {
      const campusInfo = campuses.reduce((acc, curr) => {
        const state = curr.Address.State;
        const campusType = curr?.CampusType?.Text;
        /* istanbul ignore next */
        if (!campusType) {
          return acc;
        }
        if (campusType === 'Online') {
          acc.Global = [curr];
        }
        const currState = acc?.[state] ?? [];
        acc[state] = [...currState, curr];

        return acc;
      }, {});
      const sortResult = Object.keys(campusInfo)
        .sort() // NOSONAR
        .reduce((obj, key) => {
          // eslint-disable-next-line no-param-reassign
          obj[key] = campusInfo[key];
          return obj;
        }, {});

      // Set state values.
      setCampusInformation(campusInfo);
      setSortedStates(sortResult);

      // Set state values for Global campus(es) and rest of states.
      const { Global, ...rest } = sortResult;
      setGlobalCampus({ Global: [...Global] });
      setRestOfStates(rest);
    }
  }, [campuses]);

  /**
   * Single-run convenience effect to set preferred campus when userInfo is
   * updated. Note that the default preferred campus is only set if userInfo
   * has a value, and if defaultCampus is not presently set on userInfo. This
   * results in a one-time setting of the default campus and prevents it from
   * changing or needing to be passed in separately.
   */
  React.useEffect(() => {
    if (campuses && userInfo && !defaultCampusHasChecked) {
      let queryCampus = getQueryParams('campus');
      if (typeof queryCampus !== 'string') {
        queryCampus = getQueryParams('location');
      }
      const campusGuid = getGuidFromValue({
        campuses,
        campusSlug: queryCampus,
      });
      /**
       * Note: Ignored test lines due to the fact that this condition should not
       * ever be present, as getQueryParams should return string or null every
       * time, and this was put in as a severe edge-case guard. As such, safe
       * to ignore because even calling getQueryParams() only ever returns a
       * string no matter what is set on the campus query param in tests.
       * The second ignore is due to the "OR" condition with the queryCampus.
       * It's not entirely clear how or why that conditional is set up as it is,
       * since it'd be truthy for any queryCampus inclusion, and testing against
       * it is near impossible since even matching the nested conditional before
       * the logical "OR" satisfies it, regardless of userInfo.campus, etc.
       */
      /* istanbul ignore next */
      if (typeof queryCampus !== 'string') {
        queryCampus = null;
      }
      const pc = campuses.find(
        (campus) => campus.Guid.toLowerCase() === campusGuid?.toLowerCase(),
      );
      /* istanbul ignore next */
      if (
        /* istanbul ignore next */
        (!userInfo.defaultCampus && userInfo.campus && pc && queryCampus) ||
        queryCampus
      ) {
        const dpc = campuses.find(
          (campus) => campus.Guid.toLowerCase() === campusGuid?.toLowerCase(),
        );
        setDefaultPreferredCampus(dpc);
        handleUserInfo({ ...userInfo, defaultCampus: dpc?.Guid });
      }
      setDefaultCampusHasChecked(true);
    }
  }, [campuses, defaultCampusHasChecked, handleUserInfo, userInfo]);

  /**
   * Single-run convenience effect to fire off Segment page view event.
   */
  /* istanbul ignore next */
  React.useEffect(() => {
    callSegmentPage({
      category: null,
      name: SCREENS.multiStep.location,
      properties: {},
    });
  }, []);

  return (
    <>
      {campuses &&
      campusInformation &&
      sortedStates &&
      globalCampus &&
      restOfStates ? (
        <section className="section display-flex flex-column">
          <div className="inner-section">
            <div className="header display-flex">
              <BackButton className="back-button" onClick={handleBack} />
              <h1 className="text-group_header mb-none align-center">
                {`${activeStep}. ${strings.step.titles.step}`}
              </h1>
            </div>
            <StepsIndicator
              activeStep={Array.from({ length: activeStep }, (_, i) => i + 1)}
              userInUS={userInUS}
            />
            <div className="small-header align-left">
              <h2 className="text-left">{strings.step.titles.question}</h2>
              {formErrors.campus && (
                <div className="error">{formErrors.campus}</div>
              )}
            </div>

            {/* Include default preferred campus as separate radio button group. */}
            {
              /* istanbul ignore next */ defaultPreferredCampus &&
              Object.keys(defaultPreferredCampus).length ? (
                <InputListCard
                  defaultValue={userInfo.campus.toLowerCase()}
                  description={
                    defaultPreferredCampus?.Address?.Street1
                      ? `${defaultPreferredCampus.Address.Street1}, ${defaultPreferredCampus.Address.City}, ${defaultPreferredCampus.Address.State}`
                      : null
                  }
                  icon={<MyLocationIcon />}
                  inputName="defaultPreferredCampus"
                  isMyLocation={true}
                  key={`campus-${defaultPreferredCampus.Guid}`}
                  label={defaultPreferredCampus.Name}
                  onChange={handleChange}
                  type="radio"
                  value={defaultPreferredCampus.Guid.toLowerCase()}
                />
              ) : null
            }

            {/* Iterate over Global locations as separate radio button group. */}
            {/* Ignoring for test coverage as only one should ever exist with values as-intended and checked for here. */}
            {
              /* istanbul ignore next */ globalCampus &&
                Object.entries(globalCampus).map(([state, value]) => (
                  <div className="input-item-wrapper text-left" key={state}>
                    <p className="state">
                      {state === 'Global'
                        ? 'Global'
                        : getStateNameFromCode(state)}{' '}
                    </p>
                    {value.map((campus) => (
                      <InputListCard
                        defaultValue={userInfo.campus.toLowerCase()}
                        description={
                          campus?.Address?.Street1
                            ? `${campus.Address.Street1}, ${campus.Address.City}, ${campus.Address.State}`
                            : null
                        }
                        icon={<MyLocationIcon />}
                        inputName="globalCampus"
                        isMyLocation={
                          userInfo.campus.toLowerCase() ===
                            campus?.Guid?.toLowerCase() &&
                          userInfo.campus.toLowerCase() ===
                            defaultPreferredCampus?.Guid?.toLowerCase()
                        }
                        key={`campus-${campus.Guid}`}
                        label={campus.Name}
                        onChange={handleChange}
                        type="radio"
                        value={campus.Guid.toLowerCase()}
                      />
                    ))}
                  </div>
                ))
            }

            {/* Iterate over the rest of the locations as separate and main radio button group. */}
            {/* Ignoring for test coverage as only one should ever exist with values as-intended and checked for here. */}
            {restOfStates &&
              Object.entries(restOfStates).map(([state, value]) => (
                <div className="input-item-wrapper text-left" key={state}>
                  <p className="state">
                    {
                      /* istanbul ignore next */ state === 'Global'
                        ? 'Global'
                        : getStateNameFromCode(state)
                    }
                  </p>
                  {value.map((campus) => (
                    <InputListCard
                      defaultValue={userInfo.campus.toLowerCase()}
                      description={
                        campus?.Address?.Street1
                          ? `${campus.Address.Street1}, ${campus.Address.City}, ${campus.Address.State}`
                          : null
                      }
                      icon={<MyLocationIcon />}
                      inputName="campus"
                      isMyLocation={
                        userInfo.campus.toLowerCase() ===
                          campus?.Guid?.toLowerCase() &&
                        userInfo.campus.toLowerCase() ===
                          defaultPreferredCampus?.Guid?.toLowerCase()
                      }
                      key={`campus-${campus.Guid}`}
                      label={campus.Name}
                      onChange={handleChange}
                      type="radio"
                      value={campus.Guid.toLowerCase()}
                    />
                  ))}
                </div>
              ))}
          </div>
          <StickyButton
            handleSubmit={handleSubmit}
            id="id_btn_next"
            text={strings.general.next}
            type="submit"
          />
        </section>
      ) : null}
    </>
  );
};

export default Location;
