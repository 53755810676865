/* eslint-disable no-param-reassign */
/**
 * @module BasicInfo
 */
import React from 'react';
import {
  callSegmentPage,
  callSegmentTrack,
} from '@io/web-tools-io/dist/utils/helpers/analytics';
import Input from '../../../../components/Form/Input/Input';
import { ACTIONS, EVENTS, SCREENS } from '../../../../helpers/constants';
import StepsIndicator from '../StepsIndicator/StepsIndicator';
import StickyButton from '../../components/StickyButton';
import { STRINGS } from '../../helpers/strings';

/**
 * Represents the Basic Info step for the form flow.
 *
 * @param {object} props - The component props object.
 * @param {number} props.activeStep - The step number associated with the screen.
 * @param {Function} props.handleActiveStep - Handler function for setting active step.
 * @param {Function} props.handleUserInfo - Handler function for updating user info data object.
 * @param {number} props.stringStepIndex - The number that associates the step with strings.
 * @param {object} props.userInfo - The user info data object.
 * @param {boolean} props.userInUS - Boolean flag that indicates if the user is located in the United States.
 *
 * @returns {React.ReactElement} The BasicInfo component.
 */
const BasicInfo = ({
  activeStep,
  handleActiveStep,
  handleUserInfo,
  stringStepIndex,
  userInfo,
  userInUS,
}) => {
  const [formErrors, setFormErrors] = React.useState({});
  const [segmentInitialized, setSegmentInitialized] = React.useState(false);
  const strings = {
    ...STRINGS,
    step: STRINGS.steps[stringStepIndex],
  };

  /**
   * Handler function for input change event.
   *
   * @param {Event} event - The change Event object.
   */
  const handleChange = (event) => {
    const { name, value } = event.target;
    let finalValue = value;
    if (name === 'birth_date') {
      const newDate = new Date(value);
      /* istanbul ignore next */
      if (newDate) {
        try {
          [finalValue] = newDate.toISOString().split('T');
        } catch (error) {
          // Silently fail and let value pass through as-is.
        }
      }
    }
    handleUserInfo({ ...userInfo, [name]: finalValue });
  };

  /**
   * Convenience function to return an age based on the supplied date of birth.
   *
   * @param {string} dateOfBirth - Date of birth string to use in determining age.
   *
   * @returns {number} The age value, based on the supplied date of birth.
   */
  const getAge = (dateOfBirth) => {
    const today = new Date();
    const birthDate = new Date(dateOfBirth);
    const month = today.getMonth() - birthDate.getMonth();
    let age = today.getFullYear() - birthDate.getFullYear();
    if (month < 0 || (month === 0 && today.getDate() < birthDate.getDate())) {
      age -= 1;
    }
    return age;
  };

  /**
   * Convenience function to check validity of supplied date.
   *
   * @param {string} dateOfBirth - Date of birth date string.
   *
   * @returns {boolean} Boolean flag denoting whether or not the supplied date is before today.
   */
  const isValidDate = (dateOfBirth) => {
    const today = new Date();
    const birthDate = new Date(dateOfBirth);
    return today > birthDate && birthDate.getFullYear() >= 1900;
  };

  /**
   * Convenience function to check validity of form input values.
   *
   * @param {object} values - Object of input field values against which to validate.
   *
   * @returns {boolean} Boolean flag denoting whether or not the supplied values are all valid.
   */
  const isValid = (values) => {
    const errors = {};
    if (!values.first_name) {
      errors.first_name = 'Please complete this required field.';
    }
    if (!values.last_name) {
      errors.last_name = 'Please complete this required field.';
    }
    if (!isValidDate(userInfo.birth_date)) {
      errors.birth_date = 'Please enter a valid date.';
    }
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  /**
   * Handler function for next button click.
   *
   * @param {Event} event - The Event object associated with the submit event.
   *
   * @returns {Function} Active step handler function invocation.
   */
  const handleSubmit = (event) => {
    event.preventDefault();
    if (!isValid(userInfo)) {
      return null;
    }
    /* istanbul ignore next */
    callSegmentTrack({
      event: EVENTS.buttonAction,
      properties: {
        action: ACTIONS.clicked,
        component: 'Serving Form',
        component_url: null,
        label: event.currentTarget.textContent,
        logged_in: !!userInfo.person_alias_id,
        preferred_campus: null, // User preferred campus not presently available without specific call to API to get user-specific data (such as with Web Giving).
        referrer: document?.referrer || null,
        screen: SCREENS.multiStep.basicInfo,
        title: document?.title || '',
        url: window?.location?.href,
        user_id: userInfo.person_alias_id,
      },
    });
    if (getAge(userInfo.birth_date) < 18) {
      const userEmailQuery = userInfo?.email ? `&Email=${userInfo.email}` : '';
      const formUrl = `https://my.life.church/forms/student-leader?FirstName=${userInfo?.first_name}&LastName=${userInfo?.last_name}${userEmailQuery}&utm_source=life_church`;
      window.location.href = formUrl;
      return null;
    }
    return handleActiveStep(activeStep);
  };

  /**
   * Single-run convenience effect to fire off Segment page view event once
   * window.analytics is available.
   */
  React.useEffect(() => {
    /* istanbul ignore next */
    if (window.analytics && !segmentInitialized) {
      setSegmentInitialized(true);
      callSegmentPage({
        category: null,
        name: SCREENS.multiStep.basicInfo,
        properties: {},
      });
      callSegmentTrack({
        event: EVENTS.multiStep.signUpStarted,
        properties: {
          referrer: '/serving',
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.analytics]);

  /**
   * Convenience function to format a user birth date as MM/DD/YYYY from YYYY-MM-DD.
   *
   * @returns {string} User birth date formatted as 'MM/DD/YYYY'.
   */
  const formatUserBirthDate = () => {
    const splitValue = userInfo?.birth_date
      ? userInfo.birth_date.split('-')
      : [];
    return splitValue.length === 3
      ? `${splitValue[1]}/${splitValue[2]}/${splitValue[0]}`
      : '';
  };

  /**
   * Single-run convenience effect to auto-focus first input field and add
   * keyboard events to input fields.
   * Note: The ignore next directive is for test coverage, as when rendering the
   * component, there should never be a case where inputs and formButton are
   * null or undefined, but for the sake of solid code, the check remains as a
   * fail-safe for null references in case of front-end user UI manipulation.
   * Similarly, for the focus timeout, it should not be needed and since the
   * element is immediately in the DOM for test coverage, safe to ignore.
   */
  React.useEffect(() => {
    function addKeyboardListeners() {
      const inputs = document.querySelectorAll('input');
      const formButton = document.querySelector('button#id_btn_next');
      /* istanbul ignore next */
      if (inputs && formButton) {
        [...inputs].forEach((input) => {
          if (input.id === 'id_birth_date') {
            /**
             * Separate keyup listener for formatting automatically after key
             * and character added or ignored.
             */
            input.addEventListener('keyup', () => {
              const pureValue = input.value.replace(/[^0-9]/g, '');
              const inputLength = pureValue.length;
              let displayValue = pureValue;

              if (inputLength === 3 || inputLength === 4) {
                displayValue = `${pureValue.substring(
                  0,
                  2,
                )}/${pureValue.substring(2)}`;
              }
              if (inputLength >= 5) {
                displayValue = `${pureValue.substring(
                  0,
                  2,
                )}/${pureValue.substring(2, 4)}/${pureValue.substring(4)}`;
              }
              input.value = displayValue;
            });
          }
          input.addEventListener('keypress', (event) => {
            if (event.key.toLowerCase() === 'enter' || event.keyCode === 13) {
              formButton.click();
            }
            if (input.id === 'id_birth_date') {
              if (
                !['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'].includes(
                  event.key,
                )
              ) {
                event.preventDefault();
              }

              const pureValue = input.value.replace(/[^0-9]/g, '');
              const inputLength = pureValue.length;
              if (inputLength >= 8) {
                event.preventDefault();
              }
            }
          });
        });
      }
    }
    /* istanbul ignore next */
    function autoFocusFirstInput() {
      const input = document.querySelector('input');
      if (input) {
        input.focus();
      } else {
        setTimeout(autoFocusFirstInput, 250);
      }
    }
    autoFocusFirstInput();
    addKeyboardListeners();
  }, []);

  return (
    <section className="section display-flex flex-column">
      <div className="inner-section">
        <div className="header justify-center">
          <h1 className="text-title">{strings.step.titles.main}</h1>
        </div>
        <h2 className="text-group_header mb-none align-center">
          {`${activeStep}. ${strings.step.titles.step}`}
        </h2>
        <StepsIndicator activeStep={[1]} userInUS={userInUS} />
        <div>
          <Input
            className={formErrors.first_name && 'input-error'}
            data-testid="id_first_name"
            defaultValue={userInfo.first_name}
            error={formErrors.first_name}
            inputName="first_name"
            label={strings.step.labels.firstName}
            onChange={handleChange}
            placeholder=""
            type="text"
          />
          <Input
            className={formErrors.last_name && 'input-error'}
            data-testid="id_last_name"
            defaultValue={userInfo.last_name}
            error={formErrors.last_name}
            inputName="last_name"
            label={strings.step.labels.lastName}
            onChange={handleChange}
            placeholder=""
            type="text"
          />
          <Input
            className={formErrors.birth_date && 'input-error'}
            data-testid="id_birth_date"
            defaultValue={formatUserBirthDate()}
            error={formErrors.birth_date}
            hint="MM/DD/YYYY"
            id="id_birth_date"
            inputName="birth_date"
            label={strings.step.labels.dateOfBirth}
            onChange={handleChange}
            pattern="[0-9]*"
            placeholder="MM/DD/YYYY"
            type="text"
          />
        </div>
      </div>
      <StickyButton
        handleSubmit={handleSubmit}
        id="id_btn_next"
        text={strings.general.next}
        type="submit"
      />
    </section>
  );
};

export default BasicInfo;
