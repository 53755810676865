/**
 * @module InputListCard
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import { convertValueToClassName } from '@io/web-tools-io/dist/utils/helpers/validators';
import './InputListCard.scss';

/**
 * Represents an Input List Card form control element.
 *
 * @param {object} props - The component props object.
 * @param {number|string} [props.defaultValue] - The default value to set (mark as checked), if present as `guid`.
 * @param {string} [props.description] - The description (sub-text content) for the component.
 * @param {*} [props.icon] - Optional icon to render in the component.
 * @param {string} props.inputName - The name of the input, used as <input> element `name` attribute.
 * @param {boolean} [props.isMyLocation] - Boolean flag denoting whether or not the card is user's location.
 * @param {string} [props.label] - The label (main text title content) for the component.
 * @param {Function} [props.onChange] - Handler function for change events.
 * @param {string} props.type - The type of input rendered in the component.
 * @param {number|string} props.value - The value of the component input.
 *
 * @returns {React.ReactElement} The InputListCard component.
 */
const InputListCard = ({
  defaultValue,
  description,
  icon,
  inputName,
  isMyLocation,
  label,
  onChange,
  type,
  value,
}) => {
  return (
    <div className={`form-control display-flex`}>
      <label className="label">
        <input
          checked={value === defaultValue}
          className={convertValueToClassName(type)}
          data-value={value}
          name={inputName}
          onChange={onChange}
          type={type}
        />
        <div className="text text-container display-flex flex-column align-left text-left justify-left">
          {label ? <span className="label-text">{label}</span> : null}
          {isMyLocation ? (
            <span className="justify-center">{icon || null}</span>
          ) : null}
          {description ? (
            <span className="description-text text-paragraph_small mb-auto">
              {description}
            </span>
          ) : null}
        </div>
      </label>
    </div>
  );
};

export default InputListCard;
