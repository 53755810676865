/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/**
 * @module MinistryTeam
 */
import React from 'react';
import axios from 'axios';
import {
  callSegmentPage,
  callSegmentTrack,
} from '@io/web-tools-io/dist/utils/helpers/analytics';
import { getSearchParamsFrom } from '@io/web-tools-io/dist/utils/helpers/utmParams';
import StepsIndicator from '../StepsIndicator/StepsIndicator';
import Button from '../../../../components/ButtonItem/ButtonItem';
import { ACTIONS, EVENTS, SCREENS } from '../../../../helpers/constants';
import InputListCard from '../../components/InputListCard';
import BackButton from '../../components/BackButton';
import StickyButton from '../../components/StickyButton';
import { USER_DATA_ERROR_MAP, USER_DATA_MAP } from '../../helpers/maps';
import { STRINGS } from '../../helpers/strings';

/**
 * Represents the Ministry Team selection step for the form flow.
 *
 * @param {object} props - The component props object.
 * @param {number} props.activeStep - The step number associated with the screen.
 * @param {Array} props.campuses - Array of campus data objects.
 * @param {Function} props.handleActiveStep - Handler function for setting active step.
 * @param {Function} props.handleUserInfo - Handler function for updating user info data object.
 * @param {number} props.stringStepIndex - The number that associates the step with strings.
 * @param {object} props.userInfo - The user info data object.
 * @param {boolean} props.userInUS - Boolean flag that indicates if the user is located in the United States.
 *
 * @returns {React.ReactElement} The MinistryTeam component.
 */
const MinistryTeam = ({
  activeStep,
  campuses,
  handleActiveStep,
  handleUserInfo,
  stringStepIndex,
  userInfo,
  userInUS,
}) => {
  const [campus, setCampus] = React.useState(null);
  const [formError, setFormError] = React.useState(null);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const strings = {
    ...STRINGS,
    step: STRINGS.steps[stringStepIndex],
  };
  const [utmParams, setUtmParams] = React.useState({});
  const BGC_SOURCE_ID = strings.backgroundSource.id;

  /**
   * Convenience function to check and return validity of form step.
   *
   * @param {object} values - The object of value which determines if a option has been selected.
   *
   * @returns {boolean} Boolean flag denoting whether or not the step is valid.
   */
  const isValid = (values) => {
    const errors = {};
    if (!values.opportunity) {
      errors.opportunity = 'Please select an option below.';
      setFormError({ message: errors.opportunity });
    }
    return Object.keys(errors).length === 0;
  };

  /**
   * Handler function for back button click.
   *
   * @returns {Function} Handler function for active step setting.
   */
  const handleBack = () => {
    return handleActiveStep(activeStep - 2);
  };

  /**
   * Handler function for input change event.
   *
   * @param {Event} event - The change Event object.
   */
  const handleChange = (event) => {
    const { name } = event.currentTarget;
    const value = event.currentTarget.getAttribute('data-value');
    handleUserInfo({ ...userInfo, [name]: value, ...utmParams });
  };

  /**
   * Handler function for submit button click.
   *
   * @param {Event} event - The Event object associated with the submit event.
   *
   * @returns {Function} Active step handler function or error handling function.
   */
  // eslint-disable-next-line consistent-return
  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!isValid(userInfo)) {
      return null;
    }
    setIsSubmitting(true);

    // Adjust user data to normalize for correctly-attributed POST params, as
    // they are different from the internal userInfo attributes used.
    const adjustedUserData = {
      BGCSource: BGC_SOURCE_ID,
    };

    Object.entries(userInfo).forEach(([attr, value]) => {
      if (USER_DATA_MAP[attr]) {
        adjustedUserData[USER_DATA_MAP[attr]] = value;
      }
    });
    /* istanbul ignore next */
    axios
      .post(window.location.href, adjustedUserData)
      .then((response) => {
        setIsSubmitting(false);
        if (response?.errors) {
          const { errors } = response;
          // If errors found on the response, iterate over them and add to the
          // larger error output that will be displayed in the UI error section.
          if (errors?.length) {
            let errorMessage = strings.step.errors.formSubmit;
            let output = ``;
            errors.forEach((e) => {
              if (output !== '') {
                output = `${output}, ${USER_DATA_ERROR_MAP[e.param]}`;
              } else {
                output = `${USER_DATA_ERROR_MAP[e.param]}`;
              }
            });
            /**
             * This is a severe edge case conditional, as output should always
             * be set based on the above logic. Safely ignoring for tests.
             */
            /* istanbul ignore next */
            if (output !== '') {
              output = `<br>${output}.`;
              errorMessage = `${strings.step.errors.formErrors}<ul>${output}</ul>`;
              return setFormError({ message: errorMessage });
            }
          }
        } /* istanbul ignore next */ else {
          /* istanbul ignore next */
          try {
            callSegmentTrack({
              event: EVENTS.buttonAction,
              properties: {
                action: ACTIONS.clicked,
                component: 'Serving Form',
                component_url: null,
                label: event.currentTarget.textContent,
                logged_in: !!userInfo.person_alias_id,
                preferred_campus: null, // User preferred campus not presently available without specific call to API to get user-specific data (such as with Web Giving).
                referrer: document?.referrer || null,
                screen: SCREENS.multiStep.ministryTeam,
                title: document?.title || '',
                url: window?.location?.href,
                user_id: userInfo.person_alias_id,
              },
            });
          } catch (e) {
            // Silent failure acceptable for the time being.
          }
          // Update user info with background check data.
          handleUserInfo({ ...userInfo, formData: response.data });
          return handleActiveStep(activeStep);
        }
        /**
         * This is a redundant return to satisfy consistent return in code check
         * and can safely be ignored, as the if/else above calls this in the
         * else clause.
         */
        /* istanbul ignore next */
        return handleActiveStep(activeStep);
      })
      .catch((error) => {
        setIsSubmitting(false);
        /**
         * This is a pretty unlikely edge case, as a catch should always have an
         * error. As such, safe to ignore mimicking a response without an error,
         * knowing the logic within it has been tested and covered.
         */
        /* istanbul ignore next */
        if (error) {
          const { response } = error;
          if (response) {
            const { errors } = response.data;
            if (errors?.length) {
              let output = ``;
              errors.forEach((e) => {
                if (output !== '') {
                  output = `${output}, ${USER_DATA_ERROR_MAP[e.param]}`;
                } else {
                  output = `${USER_DATA_ERROR_MAP[e.param]}`;
                }
              });
              /**
               * This is a severe edge case conditional, as output should always
               * be set based on the above logic. Safely ignoring for tests.
               */
              /* istanbul ignore next */
              if (output !== '') {
                output = `<br>${output}.`;
                const errorMessage = `${strings.step.errors.formErrors}<ul>${output}</ul>`;
                return setFormError({ message: errorMessage });
              }
            }
          }
        } else {
          /* istanbul ignore next */
          try {
            callSegmentTrack({
              event: EVENTS.buttonAction,
              properties: {
                action: ACTIONS.clicked,
                component: 'Serving Form',
                component_url: null,
                label: event.currentTarget.textContent,
                logged_in: !!userInfo.person_alias_id,
                preferred_campus: null, // User preferred campus not presently available without specific call to API to get user-specific data (such as with Web Giving).
                referrer: document?.referrer || null,
                screen: SCREENS.multiStep.ministryTeam,
                title: document?.title || '',
                url: window?.location?.href,
                user_id: userInfo.person_alias_id,
              },
            });
          } catch (e) {
            // Silent failure acceptable for the time being.
          }
          /**
           * This is a redundant return to satisfy consistent return in code
           * check and can safely be ignored, as the if/else above calls this in
           * the else clause.
           */
          /* istanbul ignore next */
          return handleActiveStep(activeStep);
        }
        /**
         * This is a redundant return to satisfy consistent return in code check
         * and can safely be ignored, as the if/else above calls this in the
         * else clause.
         */
        /* istanbul ignore next */
        return handleActiveStep(activeStep);
      });
  };

  /**
   * Convenience effect to set campus once it is found on userInfo object. This
   * filters to get the campus based on the GUID value passed in via userInfo.
   */
  React.useEffect(() => {
    if (campuses) {
      setCampus(
        campuses.find((c) => {
          return c.Guid.toLowerCase() === userInfo.campus.toLowerCase();
        }),
      );
    }
  }, [campuses, userInfo.campus]);

  /**
   * Single-run convenience effect to find UTM params in URL and apply to the
   * state value of utmParams which is ultimately attached to userInfo.
   */
  React.useEffect(() => {
    const windowLocationSearchParams = getSearchParamsFrom(
      window?.location?.href,
    );
    const filteredUtmParams = windowLocationSearchParams
      ?.split('&')
      .filter((param) => param.startsWith('utm_'));
    if (filteredUtmParams?.length) {
      filteredUtmParams.forEach((param) => {
        const [key, value] = param.split('=');
        setUtmParams({ ...utmParams, [key]: value });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Single-run convenience effect to check and correct opportunity, if found on
   * the userInfo object, to ensure it is an available opportunity for the
   * selected location. If the opportunity is not available at the selected
   * location, unset the opportunity on userInfo.
   */
  React.useEffect(() => {
    /**
     * Convenience function to check if the specified opportunity is available at the selected campus location.
     *
     * @param {string} opportunity - The ministry team opportunity guid value.
     *
     * @returns {boolean} Boolean flag denoting whether or not the specified opportunity is available at the selected campus location.
     */
    function opportunityAvailableForCampus(opportunity) {
      return campus?.AvailableOpportunities?.find(
        (o) => o.Value.toLowerCase() === opportunity?.toLowerCase(),
      );
    }

    // Once there is campus and userInfo data, check if userInfo has a specified
    // opportunity and set/unset it based on availability at the selected campus
    // location as well as presence of query param value.
    if (campus && userInfo?.opportunity) {
      if (!opportunityAvailableForCampus(userInfo?.opportunity)) {
        // Opportunity not found at selected location. Un-set on userInfo.
        handleUserInfo({ ...userInfo, opportunity: '' });
      }
    }
  }, [campus, handleUserInfo, userInfo]);

  /**
   * Single-run convenience effect to fire off Segment page view event.
   */
  /* istanbul ignore next */
  React.useEffect(() => {
    callSegmentPage({
      category: null,
      name: SCREENS.multiStep.ministryTeam,
      properties: {},
    });
  }, []);

  return (
    <>
      <section className="section display-flex flex-column">
        <div className="inner-section">
          <div className="header display-flex">
            <BackButton className="back-button" onClick={handleBack} />
            <h1 className="text-group_header mb-none align-center">
              {`${activeStep}. ${strings.step.titles.step}`}
            </h1>
          </div>
          <StepsIndicator
            activeStep={Array.from({ length: activeStep }, (_, i) => i + 1)}
            userInUS={userInUS}
          />
          {campus ? (
            <>
              {campus?.AvailableOpportunities ? (
                <>
                  <div className="small-header align-left">
                    <h2 className="text-left">
                      {strings.step.titles.question}
                    </h2>
                  </div>
                  {formError ? (
                    <div
                      className={`error`}
                      dangerouslySetInnerHTML={{
                        __html: `${formError.message}`,
                      }}
                    />
                  ) : null}
                  {campus?.AvailableOpportunities.map((opportunity) => {
                    const {
                      Description: description,
                      Text: title,
                      Value: guid,
                    } = opportunity;
                    return (
                      <InputListCard
                        defaultValue={userInfo.opportunity.toLowerCase()}
                        description={description}
                        inputName="opportunity"
                        key={`opportunity-${guid}`}
                        label={title}
                        onChange={handleChange}
                        type="radio"
                        value={guid.toLowerCase()}
                      />
                    );
                  })}
                </>
              ) : (
                <>
                  <p>{strings.step.errors.noMinistryTeams}</p>
                  <Button
                    buttonSize="large"
                    style="btn-secondary"
                    text={strings.general.connectWithOurTeam}
                    url="/nextsteps/question/"
                  />
                </>
              )}
            </>
          ) : (
            <>
              <p
                className={`error text-center text-paragraph_normal mt-normal mb-tight`}
              >
                {strings.step.errors.noCampus}
              </p>
              <p className={`text-center mt-tight`}>
                {strings.step.prompts.noCampusStepBack}
              </p>
            </>
          )}
        </div>
        {campus?.AvailableOpportunities ? (
          <StickyButton
            disabled={isSubmitting}
            handleSubmit={handleSubmit}
            id="id_btn_next"
            text={
              isSubmitting ? (
                <div className="circular loader gray100"></div>
              ) : (
                strings.general.submitForm
              )
            }
            type="submit"
          />
        ) : null}
      </section>
    </>
  );
};

export default MinistryTeam;
